.discussion-wrapper {

    .discussion-item {
        margin-bottom: 1rem;
        background-color: #F8F9FA;

        &.is-my-entry {
            background-color: #e2f4b8;
        }
    }

    .reply-row {
        margin-left: 45px;
        margin-bottom: 1rem;
        background-color: #F8F9FA;

        &.is-my-entry {
            background-color: #e2f4b8;
        }

        .edit-delete-button {
            padding-right: 15px !important
        }

        &.discussion-editor {
            background-color: #fff;

            .full-editor-row {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    .list-group-item {
        padding: 0;
        border: none;

        &:hover {
            background: none;
        }
    }

    .no-post-message {
        margin: 5px 0;
        padding: 0.25rem 0;
    }

    .avatar-responsive {
        max-width: 100%;
        height: auto;
        max-height: 40px;
        min-height: 40px;
    }

    .avatar-responsive-profile {
        height: auto;
        max-height: 40px;
        min-height: 40px;
        width: 40px;
    }


    .user-badges {
        position: absolute;
        height: 0;
        width: 0;

        .user-badge {
            color: #fff;
            background-color: #3a5dae;
            padding: 0 4px;
            font-size: 12px;
            position: relative;
            top: 12px;
            min-width: 55px;

            //IE11 fixes
            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                top: 6px;
            }

        }

        .user-flag {
            position: relative;
            //top: 9px;
            left: 32px;
            width: 1.2em;

            //IE11 fixes
            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                top: -1px;
            }

        }

    }

    .replylike-text {
        font-size: 0.9rem;
        margin-left: 5px;
        color: $info;
    }

    .like-wrapper {
        margin: 5px 5px 5px 0;
        color: $info;

        .fa {
            padding: 6px;
            border-radius: 6px;
            border-color: $info;
        }

        .is-liked {
            .fa-thumbs-up {
                background-color: $info !important;
                color: #fff !important;
            }

            .fa-spin {
                background-color: transparent;
                font-size: 1.3rem;
            }
        }

        :not(.is-liked) {
            .fa-thumbs-up {
                background-color: #fff;
                color: $info;
            }

            .fa-spin {
                background-color: transparent;
                font-size: 1.3rem;
            }
        }

        .like-text {
            font-size: 0.9rem;
            margin-left: 5px;
        }
    }

    .btn-link {
        font-size: 0.97rem;
    }

    .like-text {
        display: inline-block;
        min-width: 60px;
    }

    .comment-box-user {
        padding: 10px 12px;

        .user-details {
            padding-top: 0;
            padding-left: 25px;
        }
    }

    .comment-box-main {
        padding: 0 12px 8px 12px;
        word-break: break-word;
    }

    .alert-discusssion {
        line-height: 1.8;

        .btn {
            margin-left: 0 !important;
            width: 100%;
        }
    }

    .ngx-editor-textarea {
        background-color: #fff !important;
    }

    .post-to-discussion {
        border: 1px solid #ddd;
        background-color: #fff;
        color: #888;
        width: 100%;
        text-align: left;
        cursor: text !important;

        &:focus,
        &:hover,
        &:active {
            box-shadow: none;
            background-color: #fff;
        }
    }

    .snapshot-text {
        overflow: hidden;
        margin-bottom: 1px;
        min-height: 70px;

        p {
            overflow: hidden;
            word-wrap: break-word;
            text-overflow: ellipsis;
            width: 100%;
            margin-bottom: 0;
        }

        .display-name {
            overflow: hidden;
            text-overflow: ellipsis;
            height: 50px;
            width: 100%;
        }

        .row {
            margin-top: 0 !important;
        }

        .btn {
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }

    .snapshot-text-replies {
        padding-top: 0;
        padding-right: 12px;
    }

    .text-muted {
        color: #333 !important;
    }

    .loaded-style {
        margin-top: 8px;
        font-size: 0.9rem;
        font-weight: bold;
    }

    @include media-breakpoint-up(sm) {
        .comment-box-main {
            padding-left: 0;

            row {
                min-height: 70px;
            }
        }

        .avatar-responsive {
            max-height: 50px;
            min-height: 50px;
        }

        .avatar-responsive-profile {
            max-height: 50px;
            min-height: 50px;
            width: 50px;
        }

        .comment-box-user {
            flex: 0 0 110px;

            .user-details {
                padding-left: 0;
                padding-top: 10px;
            }
        }

        .comment-box-main {
            padding: 12px;
        }

        .alert-discusssion {
            line-height: auto;

            .btn {
                margin-left: 1rem !important;
                width: auto;
            }
        }

        .reply-row {
            margin-left: 110px;

            &.discussion-editor {
                .full-editor-row {
                    margin-left: -15px;
                    margin-right: -15px;
                }
            }
        }

        .user-badges {
            .user-badge {
                top: 35px;
            }

            .user-flag {
                top: 27px;
                left: 37px;
                width: 1.5em;
            }
        }
    }
}

// ebs-discussion-comment {
//     p {
//         margin-bottom: 0;
//     }
// }

.forum-description {
    p {
        margin: 0 !important;
        padding: 0 !important;
    }
}

.tag-container {

    .btn-light,
    .btn-success {
        @include media-breakpoint-down(sm) {
            max-width: 300px;
        }
    }
}

.customTooltip {
    .tooltip-inner {
        background-color: #fff !important;
        color: #000;
        box-shadow: 1px 1px 6px 3px #ccc;
        cursor: pointer;
    }
}

.forum-wrapper,
.forum-list-wrapper {
    @include media-breakpoint-down(sm) {

        .card-header,
        .card-body {
            padding: 5px;
        }
    }
}

.unread-indicator {
    color: $danger;
    line-height: inherit !important;
    vertical-align: top;
    padding-top: 2px;
}

//changes for refactor -- incorporate properly
.discussion-wrapper {
    .user-badges {
        position: inherit !important;
    }

    @include media-breakpoint-down(sm) {
        .user-avatar-container {
            padding-left: 5px;
        }

        .nav-tabs{
            .nav-item {
                width: 50%;
            }
        } 
    }
    @include media-breakpoint-up(sm) {
        .user-avatar-container {
            padding-left: 0;
        }
    }
}
