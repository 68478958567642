.downloads-wrapper {
    .card-body {
        overflow-y: auto;
        overflow-x: hidden;

        .btn-light {
            border: 1px solid #bbb !important;
        }
    }

    .fa-trash-alt {
        opacity: 0.85;
    }

    .fa-download {
        opacity: 0.85;
    }

    .beta-version {
        box-shadow: none !important;
        margin: 0;
        margin-bottom: 10px;
        padding: 1.5rem;
        padding-bottom: 0.5rem;
        border-radius: 3px;
        border: 1px solid #818ba2;
        p{
            margin-bottom: 1rem;
        }
    }
}

// tables from canvas have the first row as the table header (not a proper thead) so we have to override these styles
.downloads-current-wrapper,
.downloads-available-wrapper {
    .table-responsive {
        table {
            thead {
                tr {
                    background: #f7f7f7 !important;
                    box-shadow: 0 0 3px 1px #e7e7e7 !important;
                    font-weight: bold !important;
                    font-size: 1.1rem !important;
                }
            }

            //need to override general tables that don't have headers
            tbody {
                tr:first-child {
                    background: #fff !important;
                    box-shadow: none !important;
                    font-weight: normal !important;
                    font-size: 1rem !important;
                }
            }

            @include media-breakpoint-down(sm) {
                tbody {
                    tr:first-child {
                        background: #fff !important;
                        box-shadow: none !important;
                        font-weight: normal !important;
                        font-size: 0.9rem !important;
                    }

                    tr {
                        background: #fff !important;
                        box-shadow: none !important;
                        font-weight: normal !important;
                        font-size: 0.9rem !important;
                    }
                }
            }
        }
    }
}

.downloads-available-wrapper {
    .progress-bar-wrapper {
        min-width: 100px;
        padding-top: 4px;

        @include media-breakpoint-down(sm) {
            min-width: 40px;
        }

        progressbar {
            border-right: 1px solid #aaa;
            border-radius: 0 !important;
        }
    }

    @include media-breakpoint-down(sm) {
        .progress-col {
            padding: 3px;
        }
    }
}