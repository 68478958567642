.modal-footer {
    width: 100% !important;
}

.login-wrapper {
    .modal-only {
        display: none !important;
    }
}

.modal-dialog {
    .login-wrapper {
        .not-modal {
            display: none !important;
        }

        .modal-only {
            display: block !important;
        }
    }

    ebs-login {
        .ebs-text {
            display: none;
        }

        .border-panel {
            border: solid 2px #e7e7e7;
            border-radius: 8px;
        }

        .card-body {
            margin: 0;
            padding: 0;
            max-width: 100%;
        }
    }

    .modal-header {
        justify-content: left;
    }

    .modal-content {
        border-radius: 6px;
    }
}

@include media-breakpoint-up(md) {
    .modal-dialog {
        width: 700px;
        max-width: 700px;
        margin: 1.75rem auto;
    }

    .modal-footer {
        justify-content: flex-end;
    }
}

.btn-success {
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}

.btn-warning {
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
}

.modal-footer-offline {
    justify-content: flex-end;
}

@include media-breakpoint-down(sm) {
    .modal-dialog {
        .modal-body {
            padding: 5px;

            .card {
                padding: 0;
            }
        }
    }
    .offlinemode-wrapper {
        .card {
            margin: 0 !important;
            padding: 0 !important;
        }
    }
}

//if there are 2 modals on top of each other we need them not to overlap
body {
    modal-container:nth-of-type(2) {
        margin: 20px;
    }
}

.modal-footer {
    justify-content: space-between;
}