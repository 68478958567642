/* ---------------------------------------------------
    SIDEBAR COLLAPSE BUTTONS STYLE
----------------------------------------------------- */
#sidebarCollapse.btn-sidebar-trigger {
    border-radius: 50%;
    padding: 10px 10px;
    text-align: center;
    line-height: 1;
    margin-right: 15px !important;
    font-size: 1.3rem;
    margin: 0;

    span {
        line-height: 1;
        //font-size: 1.25rem;
        display: inline-block;
    }
}



/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

#content-wrapper {
    #sidebar-wrapper {
        background: $gray-700;
        padding-bottom: 20px;
        transition: all 0.3s;
        padding-right: 0 !important;
        padding-top: 2px !important;
        padding-left: 5px !important;

        #sidebar {

            height: 100%;
            color: #fff;
            transition: all 0.3s;

            /* minimise button/trigger */
            .button-wrapper {
                width: 100%;

                #sidebarCollapse {
                    color: $gray-400;
                    background-color: transparent;
                    border: none;

                    &:focus {
                        box-shadow: none;
                    }

                }
            }

            a {
                color: #fff;
                display: block;
                /* &[aria-expanded="true"] {
                    color: #fff;
                    background: $gray-800;
                }
                */


            }

            .divider {
                border-bottom: 1px solid $gray-800;

                a {
                    background-color: transparent;
                    padding-left: 10px !important;
                    margin-left: -15px;

                    &:hover {
                        background: rgba($gray-800, .5);
                    }
                }
            }

            >ul {
                border-top: 1px solid $gray-800;
            }

            .dropdown {
                .btn-nav-dropdown {
                    white-space: inherit;
                    background-color: transparent;
                    border: none;
                    color: white;
                    border-radius: 0;

                    &:focus {
                        box-shadow: none;
                    }

                    &:hover {
                        background: rgba($gray-800, .5);
                    }
                }
            }

            li {
                position: relative;
                cursor: pointer;
                &.active {
                    >a {
                        border-radius: 0;
                        color: #000;
                        background: $blue;

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }


            ul {
                a {
                    font-size: 0.9em !important;

                    background: $gray-800;
                }

                .divider {
                    &.active {
                        a {
                            color: #000;
                            background: $blue;
                        }

                        &:hover {
                            color: #fff;
                        }
                    }

                }
            }

        }

    }

    &:not(.sidebar-minimised) {
        #sidebar {
            .button-wrapper {
                text-align: right;

                #sidebarCollapse {
                    span {
                        &.icon-gecko-chevron-right {
                            display: inherit;
                        }

                        &.icon-gecko-chevron-left {
                            display: inherit;
                        }
                    }
                }
            }

            .dropdown {
                .btn-nav-dropdown {
                    padding: 0.75rem;

                    .nav-index {
                        left: 30px;
                    }

                    .nav-label {
                        text-align: left;
                        padding-left: 35px;

                        &:hover {
                            background: transparent;
                            text-decoration: none;
                        }
                    }

                    &::before {
                        display: block;
                        position: absolute;
                        left: 5px;
                        top: 20px;
                        width: 0;
                        height: 0;
                        margin-left: $caret-width * .85;
                        vertical-align: $caret-width * .85;
                        content: "";
                        color: $gray-500;
                    }

                    &:not(.collapsed) {
                        &::before {
                            @include caret-down;
                        }
                    }

                    /*
                    &.collapsed{
                        &::before {
                            @include caret-right;
                        }
                    }
                    */
                    // Updated to include styling for unavailable modules
                    &.collapsed {
                        &:not(.unavailable) {
                            &::before {
                                @include caret-right;
                            }
                        }
                    }

                    &.unavailable {
                        &::before {
                            left: 2px;
                            top: 15px;
                            font-family: $icomoon-font-family;
                            font-size: $font-size-sm;
                            content: $icon-gecko-unavailable !important;
                        }

                        .nav-index,
                        .nav-label {
                            color: $gray-200;
                        }
                    }
                }
            }

            .nav-title {
                padding: .75rem;
                padding-left: 30px;
                font-size: $small-font-size;
                //font-weight: 600;
                color: $gray-200;
                text-transform: uppercase;
                display: block;
            }


            li {


                a {
                    text-align: left;
                    padding: 12px 10px;
                    //font-size: 1.1em;
                    display: block;
                    padding-left: 30px !important;

                    &:hover {
                        background: rgba($gray-800, .5);
                        text-decoration: none;
                    }

                    i {
                        margin-right: 10px;
                    }
                }


                .nav-index {
                    display: block;
                    position: absolute;
                }

                .nav-label {
                    display: block;
                    margin-left: 0.3em;
                    position: relative;

                    &:hover {
                        //background: rgba($gray-800, .5);
                        text-decoration: underline;
                    }

                    .sidebar-icon {
                        font-size: 1.25rem;
                        position: absolute;
                        top: 0px;
                        right: 8px;

                        &.icon-gecko-check {
                            color: $success;
                        }

                        &.icon-gecko-timer {
                            color: rgba(#fff, .5);
                        }

                        &.icon-gecko-eye {
                            color: rgba(#fff, .5);
                        }
                    }

                    &.icon-left {
                        padding-left: 20px;
                    }

                    &.icon-right {
                        padding-right: 40px;
                    }
                }

                .nav-label-icon-left {
                    position: absolute;
                    padding: 4px !important;
                    padding-left: 10px !important;

                }

                .progress {
                    height: 3px;
                    background-color: $gray-800;
                    margin-top: 6px;
                }
            }


            >ul {
                >li {
                    a {
                        .nav-label {
                            padding-left: 15px;
                        }
                    }

                    >ul {
                        .nav-label {
                            padding-left: 30px;
                        }
                    }
                }
            }


        }
    }

    &.sidebar-minimised {
        @include media-breakpoint-up(md) {
            #sidebar {

                text-align: center;

                .button-wrapper {
                    text-align: center;

                    #sidebarCollapse {
                        span {
                            &.icon-gecko-chevron-right {
                                display: inherit;
                            }

                            &.icon-gecko-chevron-left {
                                display: inherit;
                            }
                        }
                    }
                }

                .nav-label,
                .nav-title {
                    display: none;
                }

                ul {
                    li {
                        a {
                            padding: 15px 10px;
                            text-align: center;
                            font-size: 0.85em;

                            i {
                                margin-right: 0;
                                display: block;
                                font-size: 1.8em;
                                margin-bottom: 5px;
                            }
                        }
                    }

                    ul {
                        margin-left: -7px;

                        a {
                            padding: 10px !important;
                            min-width: 45px;
                            padding-left: 3px !important;
                        }
                    }
                }

                .dropdown {
                    .btn-nav-dropdown {
                        padding-left: 0;
                        margin: 0;
                        padding: 7px;
                        margin-left: -7px;

                        &::before {
                            display: none;
                        }

                        &.unavailable {
                            .nav-index {
                                font-size: $font-size-sm;
                                color: $gray-200;
                            }
                        }

                    }
                }
                .spinner-wrapper {
                    margin-left: -15px;
                }

            }
        }
    }



}