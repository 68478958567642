// Header
#offline-toggle {
    font-size: 1.5rem;
    .dropdown-menu {
        z-index: 1030;
        a {color: $primary;}
    }
}

// Main content area (login and programme dashboard)
#global-content {
    overflow-y: auto;
    padding:  $spacer-v-xs $spacer-h-xs $spacer-v-xs $spacer-h-xs;
    @include media-breakpoint-up(md) { 
        padding:  $spacer-v-md 0 $spacer-v-md 0;
    }
}

.journey {
    background-image: url('/images/journey-line.svg');
    background-repeat: no-repeat;
    background-position-y: 36rem;

    @include media-breakpoint-up(md) {
        background-position-y: 12rem;
    }
}


// Card styling for login screen
.card {
    &.f-9 {
        background-color: rgba(255, 255, 255, 0.9);
    }
}

// General disabled styling for unavailable functionality
.btn.disabled, .btn:disabled {
    opacity: 0.4;
}
.form-control:disabled, label.disabled {
    opacity: 0.6;
}


// Modal dialog styling
.modal-footer {
    justify-content: space-between;
}

