// *** THIS HAS DIFFERENT PATHS THAN THE ORIGINAL FILE ***
//
// Add own or overwrite boostrap variables
//


//copy variable from /Bootstrap/_variables.scss and remove the !default;

$gray-200:      #EEEFF0;
$gray-700:      #5B6770;

$primary:       #8031A7;
$secondary:     $gray-700;
$success:       #97D700;
$info:          #3a5dae; //#007EA8; #00a9e0;
$warning:       #856404;
$danger:        #CE0037;
$blue:          #00a9e0;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "blue":    $blue,
  ),
  $theme-colors
);


// Body

$body-bg:       $gray-200; 
$body-color:    $gray-700;


// Fonts

@font-face {
    font-family: 'DroidSans';
    src: url('../assets/fonts/DroidSans-webfont.eot');
    src: url('../assets/fonts/DroidSans-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/DroidSans-webfont.woff') format('woff'),
         url('../assets/fonts/DroidSans-webfont.ttf') format('truetype'),
         url('../assets/fonts/DroidSans-webfont.svg#DroidSans-webfont') format('svg');
}

@font-face {
    font-family: 'DroidSans-Bold';
    src: url('../assets/fonts/DroidSans-Bold-webfont.eot');
    src: url('../assets/fonts/DroidSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/DroidSans-Bold-webfont.woff') format('woff'),
         url('../assets/fonts/DroidSans-Bold-webfont.ttf') format('truetype'),
         url('../assets/fonts/DroidSans-Bold-webfont.svg#DroidSans-Bold-webfont') format('svg');
}

@font-face {
    font-family: 'DroidSerif-Regular';
    src: url('../assets/fonts/DroidSerif-Regular-webfont.eot');
    src: url('../assets/fonts/DroidSerif-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/DroidSerif-Regular-webfont.woff') format('woff'),
         url('../assets/fonts/DroidSerif-Regular-webfont.ttf') format('truetype'),
         url('../assets/fonts/DroidSerif-Regular-webfont.svg#DroidSerif-Regular-webfont') format('svg');
}

$font-family-sans-serif:      "DroidSans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-sans-serif-bold: "DroidSans-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-serif:           "DroidSerif-Regular", Georgia, "Times New Roman", Times, serif;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`

$h1-font-size-mobile:         $font-size-base * 2;
$h2-font-size-mobile:         $font-size-base * 1.75;

$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.15;

$small-font-size:             87.5%;

// Navbar

$spacer: 1rem !default;
$navbar-padding-x:            ($spacer / 2);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1540px
) !default;


// Cards

$card-spacer-y:                     0.5rem;
$card-spacer-x:                     0.8rem;


// Flags

$flag-icon-css-path: '/images/flags' !default;
$flag-icon-rect-path: '/4x3' !default;
$flag-icon-square-path: '/1x1' !default;

// Modals

$modal-inner-padding:               1.25rem;
$modal-content-border-radius:       0;
$modal-backdrop-opacity:            .7;
$modal-header-padding:              1.25rem;

$print-side-margin:                 44pt;