@mixin scrollbars_chrome($width, $foreground-color, $background-color, $hover-color, $border-radius) {

    &::-webkit-scrollbar {
        width: $width;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: $border-radius;
        height: 40px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $hover-color;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
        border-radius: $border-radius;
    }
}

@mixin scrollbars_ie($foreground-color, $background-color) {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
    scrollbar-arrow-color: $background-color;
    scrollbar-shadow-color: $foreground-color;

}

@mixin scrollbars_firefox($foreground-color, $background-color, $hover-color) {
    scrollbar-color: $foreground-color $background-color;
    scrollbar-width: thin;
    &:hover{
        scrollbar-color: $hover-color $background-color; 
    }
}

.scrollable {
    @include scrollbars_chrome(7px, #666666, #dddddd, #999999, 5px);
    @include scrollbars_ie(#999999, #dddddd);
    @include scrollbars_firefox(#666666, #dddddd, #999999);
    overflow-y: scroll;
}

.scrollable-sidebar {
    @include scrollbars_chrome(7px, #999999, #666666, #dddddd, 5px);
    @include scrollbars_ie(#999999, #666666);
    @include scrollbars_firefox(#999999, #666666, #dddddd);
    overflow-y: scroll;
}