textarea {
    width: 100%;
    height: 300px;
    padding: 10px;
    border-radius: 6px;
    border-color: #d7d7d7;
    box-shadow: 0 0 10px 3px #e7e7e7;
}

.ngx-editor {
    margin-bottom: 20px;
    margin-top: 20px;

    .ngx-editor-textarea {
        min-height: 100px !important;
    }
}

.ngx-toolbar {
    border-radius: $border-radius $border-radius 0 0;
}

.ngx-editor-grippie{
    border-radius: 0 0 $border-radius $border-radius;
}

@include media-breakpoint-down(xs) {
    .ngx-editor-parent {
        padding: 0;
    }

    .gdpr-message {
        font-size: 0.7rem;
        padding-right: 0 !important;
        padding-top: .25rem;
    }

    .ngx-toolbar {
        padding: 0 !important;
        font-size: .7rem !important;
        border: none !important;
    }  
}