header {
    background-color: #fff;
}

#course-header {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    padding-left: 1rem;

    padding: 0 50px;

    @include media-breakpoint-up(md) {
        padding: 0 50px 0 0px;
        min-height: 50px;
    }

    min-height: 48px;

    .nav-item a {
        @include media-breakpoint-down(sm) {
            display: none;
        }

        font-size: 0.9rem;
    }
}


#asideCollapse {
    @include media-breakpoint-up(md) {
        right: 2px;
    }

    padding: 4px 7px;
    font-size: 14px;
    text-align: center;
    vertical-align: top;
    line-height: 1;
    margin:5px;

    span {
        line-height: 1;
        //font-size: 1.25rem;
        display: inline-block;
    }

    background: #fff;

    &:hover {
        background: $gray-500
    }

    &.active {
        background: $secondary;

        .icon-gecko-chevron-right {
            display: inherit;
        }

        .icon-gecko-chevron-left {
            display: inherit;
        }
    }

    &:not(.active) {


        .icon-gecko-chevron-right {
            display: inherit;
        }

        .icon-gecko-chevron-left {
            display: inherit;
        }
    }



}

#sidebarToggle {
    @include media-breakpoint-down(sm) {
        left: 0;
        top: 0;
        position: relative;
        float: left;
        height: 25px;
    }

    padding: 4px 7px;
    text-align: center;
    line-height: 1;
    margin:5px;

    span {
        line-height: 1;
        //font-size: 1.25rem;
        display: inline-block;
    }

    //background: #fff;
    &:hover {
        background: $gray-500;
    }

    &.active {
        .icon-gecko-show-lines {
            display: none;
        }

        .icon-gecko-delete {
            display: inherit;
        }

        background: $gray-500;
    }

    &:not(.active) {

        .icon-gecko-show-lines {
            display: inherit;
        }

        .icon-gecko-delete {
            display: none;
        }   
    }

}

.avatar {
    // width: 48px;
    // height: 48px;
    width: 34px;
        height: 34px;

    // @include media-breakpoint-down(sm) {    
    //     width: 34px;
    //     height: 34px;
    // }

    .avatar-image {
        width: 100%;
        height: 100%;
    }

    .avatar-status {
        position: absolute;
        top: 85%;
        left: 0;
        right: 0;
        text-align: center;
    }

}


header {
    .dropdown-menu {
        min-width: 12rem !important;
    }

    ul {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }

    .dropdown-divider {
        margin: 0 !important;
    }

    .link {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;

        &:hover {
            background-color: #e7e7e7 !important;
            text-decoration: none !important;
        }

        a {
            text-decoration: none !important;
        }
    }
}