
   .unity-container,
   .unity-container canvas
    {
        height: 225px;
        margin: auto !important;
        width: 410px;
    }
   @media screen and (min-width: 1045px) {    
    .unity-container,
    .unity-container canvas
    {
       height: 450px;
       margin: auto !important;
       width: 820px;
   }
}
