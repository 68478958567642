@include media-breakpoint-down(xs) {
    //section progress
    .congrats-row {
        margin: 10px !important;

        .congrats-text {
            padding: 0;
            margin: 0;
            font-size: 0.95rem;

            .btn-link {
                font-size: 0.95rem;
            }
        }
    }
    .complete-row {
        button {
            width: 100%;
        }
    }
}