html{
    font-size: 20px;
}

.hidden-print {
    display: none !important;
}

.checkmark,
.radiobtn {
    border: 1px solid #999 !important;
    padding: 0 !important;
    margin: 0 !important;
}

.responsive-table {
    overflow-x: none;
}

pre, blockquote {
    border: none;
}

.frac-line{
    border-bottom-width: 0.05em !important;
}

.table {
    font-size: .9rem;

    th,
    td {
        padding: 0.25rem;
    }
}

.element_toggler {
    display: none !important;
}

#section-content {
    margin: 0 !important;
    padding: 0 !important;
}

#page-wrapper {
    display: block !important;
    height: auto !important;
    width: auto !important;
    margin: 0 !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    padding: 0 !important;
}

#content-wrapper {
    display: block !important;
    height: auto !important;
    width: auto !important;
    margin: 0 !important;
    padding: 0 !important;
}

.section-header {
    padding: 24px $print-side-margin;
}


#section-content-wrapper {
    display: block !important;
    height: auto !important;
    width: auto !important;
    margin: 0 !important;
    padding: 0 !important;

    div,
    .card-body {
        display: block !important;
        height: auto !important;
        width: auto !important;
    }

    .card {
        padding-left: $print-side-margin;
        padding-right: $print-side-margin;
    }

    .card-header {
        .row {
            display: flex !important;
        }
    }

    .is-feedback-question {
        display: none !important;
    }

    #progress-complete-id {
        display: none !important;
    }

    .discussion-wrapper {
        display: none !important;
    }

    .hidden-print {
        display: none !important;
    }

    button {
        display: none;
    }

    .time-container {
        font-size: 0.9rem !important;
    }
    .essay-question-print-box{
        border:1px solid #999 !important;
        width: 100% !important; 
        height: 300px !important; 
        border-radius: 3px !important;
    }

    .numerical-question-print-box{
        border:1px solid #999 !important;
        width: 200px !important; 
        height: 40px !important; 
        border-radius: 3px !important;
    }

    .table-responsive {
        table tr:first-child {

            td,
            th {
                background-color: #F7F7F7 !important;

            }
        }

        &.match-the-pairs {
            table {
                tr:first-child {
                    font-weight: normal;
                    font-size: .9rem;

                    td {
                        border-top: none;

                        ul {
                            li {
                                border-bottom: 1px solid #999;
                                list-style: none;
                                padding: 6px 0;
                            }

                            padding: 0;

                        }

                    }
                }
            }
        }    }    
    ebs-video-activity, ebs-third-party {
        a {
            i.fas {
                color: #ffffff;
            }
        }
    }        
}

.toast-container{
    display: none !important;
}