.portal-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;

    .emailToLink {
        display: inline;
        padding-right: 0;
    }



    ul {
        padding: 0;
        margin: 0;
    }

    a {
        cursor: pointer;
        padding-right: 12px;
        color: #8031a7;
        display: block;
        width: 100%;
        text-decoration: none;
    }

    .left-border {
        border-left: 1px solid #e7e7e7;
    }

    .navbar {
        font-size: 16px;
        color: #8031a7;
        font-weight: 400;
        border-bottom: 3px solid #5b6770;
        align-items: stretch;
        display: flex;
        background-color: white;
        position: relative;
        box-sizing: inherit;
        //min-height: 60px;
        min-height: 90px;
        box-sizing: border-box;
    }

    .navbar-dropdown {
        background-color: white;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: .25rem;
        box-shadow: rgb(231, 231, 231) 0px 0px 3px 1px;
        position: absolute;
        top: 60px;
        z-index: 20;
        padding-bottom: 8px;
        padding-top: 8px;
        left: auto;
        right: 0;
        visibility: hidden;

        .navbar-item {
            padding: 0.375rem 0;

            &.subitem {
                padding-left: 0;
            }
        }
    }


    .navbar-brand {
        align-items: center;
        display: none;
        flex-shrink: 0;
        min-height: 3.25rem;
    }

    .navbar-icon {
        height: 18px;
        width: 18px;
    }

    .caret-down {
        width: 14px;
        padding-left: 4px;
    }

    .logo {
        padding: 0;

        img {
            max-width: 200px; //stops menu display issue at around 1000px!
            min-width: 240px
        }
    }

    .navbar-item {
        display: flex;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
        line-height: 1.5;
        padding: 8px 12px;
        position: relative;

        a {
            width: auto;
        }

        &.top {
            display: inline-flex;
            vertical-align: middle;
            padding: 0;
            height: 60px;
        }
    }

    .navbar-item.subitem {
        padding: 4px;
        width: 100%;

        &:hover {
            background-color: #eeeff0;
        }
    }


    .navbar-menu {
        flex-grow: 1;
        flex-shrink: 0;
        align-items: stretch;
        display: flex;
    }

    .navbar-end {
        justify-content: flex-end;
        margin-left: auto;
        align-items: stretch;
        display: flex;
    }

    .navbar-link {
        line-height: 1.5;
        padding: 0.5rem 0.75rem;
        position: relative;
        align-items: center;
        display: flex;
        cursor: pointer;
        flex-direction: column;

        .menu-label {
            font-size: 14px;
        }
    }

    .navbar-divider {
        display: block;
        background-color: #eeeff0;
        border: none;
        height: 2px;
        margin: 0.5rem 0;
    }

    .column {
        display: block;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        padding: 0.75rem;
        min-width: 220px;
    }

    .menu-header {
        border-bottom: 1px solid #5B6770;
        display: block;
        line-height: 2;
        margin-bottom: 4px;
        color: #5B6770;
        font-weight: bold;
    }

    //icons
    .header-icon-large {
        padding-top: 4px;
        font-size: 30px;
    }

    .header-icon-small {
        font-size: 10px;
    }

    .btn-light {
        box-shadow: none;
    }

    .btn-light {
        color: #8031a7;
        background-color: #fff;
        border-color: #fff;

        &:hover {
            color: #8031a7;
            background-color: #fff;
            border-color: #fff;
        }
    }

    ul .dropdown-menu {
        max-height: 500px;
        overflow-y: auto;

        // p {
        //     white-space: normal;
        //     word-wrap: break-word;
        // }
    }

    a:not([href]):not([tabindex]) {
        color: #8031a7;
    }

    .link {
        &:hover {
            background-color: transparent !important;
        }
    }

    .studies-row {
        width: 100%;
    }
    @media screen and (max-width: 400px) {
        .navbar-link {
            padding: 3px !important;

            .menu-label {
                font-size: 0.8rem;
            }
        }
    }

    @media screen and (max-width: 576px) {
        .dropdown-menu-dynamic {
            max-width: 100% !important;
            min-width: 100% !important;
        }      
    }

    @media screen and (min-width: 576px) {
        .navbar {
            min-height: 90px;
        }

        .navbar-brand {
            display: flex;
        }

        .navbar-dropdown {
            top: 80px;
        }
    }

    @media screen and (min-width: 768px) {
        .navbar-menu {
            padding-top: 4px;
        }

        .navbar-item {
            white-space: nowrap;
            width: 100px;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 768px) {
        .dropdown-menu-dynamic {
            max-width: 570px;
        }
    }

    @media screen and (max-width: 768px) {
        .navbar-menu {
            padding-top: 6px;
        }

        .logo {
            img {
                max-width: 200px;
                min-width: 200px
            }
        }

    }

    @media screen and (min-width: 992px) {
        .columns {
            display: flex;
        }

        .navbar-item {
            &.top {
                min-width: 125px;
            }
        }

    }

    @media screen and (max-width: 991px) {
        .studies-row {
            max-width: 300px;
        }

        .navbar-brand {
            display: none;
        }

        .dropdown-menu-dynamic {
            max-width: 100% !important;
        }

        ul .dropdown-menu {
            p {
                white-space: normal;
                word-wrap: break-word;
            }
        }
    }

    @media screen and (min-width: 1200px) {}

    @media screen and (min-width: 1600px) {}

    .special-dropdown {
        position: fixed !important;
        top: 90px !important;
        left: auto !important;
        overflow: hidden;
    }

    .special-dropdown-right-0px {
        position: fixed !important;
        top: 90px !important;
        left: auto !important;
        right: 0px !important;
        overflow: hidden;
    }

    a,
    .btn-group,
    .btn {
        width: 100% !important;
    }
}

//IE11 fixes
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .portal-header {
        .logo {
            img {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}

@media screen and (min-width: 992px) and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .portal-header {
        .studies-row {
            min-width: 740px !important;
        }
    }
}