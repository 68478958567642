@media print {
    .course-header-bar {
        background-color: none !important;
        .course-title {
            padding: 16px $print-side-margin !important;
            a {
                text-decoration: none !important;
            }
        }
    }

    .hide-show-class {
        display: block !important;
    }

    .d-flex {
        display: block !important;
        margin: 0 !important;
        padding: 0 !important;
        font-size: 0.9rem;
        width: 100% !important;
    }

    @import "default-print";

    @-moz-document url-prefix() {
        @import "default-print";
    }    
}

@media print and(-ms-high-contrast: active),
print and (-ms-high-contrast: none) {
    @import "default-print";
}