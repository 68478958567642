$icomoon-font-family: "EBS" !default;
$icomoon-font-path: "../../assets/fonts" !default;

$icon-gecko-offline: "\e934";
$icon-gecko-online: "\e937";
$icon-gecko-sync: "\e935";
$icon-gecko-unavailable: "\e936";
$icon-gecko-dashboard: "\e932";
$icon-gecko-download: "\e933";
$icon-gecko-like: "\e930";
$icon-gecko-reply: "\e931";
$icon-gecko-external-link: "\e92b";
$icon-gecko-knowledge: "\e92c";
$icon-gecko-overview: "\e92d";
$icon-gecko-scroll-down: "\e92e";
$icon-gecko-poll: "\e92f";
$icon-gecko-eye: "\e900";
$icon-gecko-activity: "\e901";
$icon-gecko-connect: "\e929";
$icon-gecko-audio: "\e920";
$icon-gecko-discussion: "\e921";
$icon-gecko-globe: "\e922";
$icon-gecko-home: "\e923";
$icon-gecko-light: "\e924";
$icon-gecko-question: "\e925";
$icon-gecko-text: "\e926";
$icon-gecko-timer: "\e927";
$icon-gecko-video: "\e928";
$icon-gecko-grid: "\e91e";
$icon-gecko-map: "\e91f";
$icon-gecko-scroll-up: "\e91d";
$icon-gecko-sidebar: "\e91c";
$icon-gecko-calendar: "\e902";
$icon-gecko-cart: "\e903";
$icon-gecko-check: "\e904";
$icon-gecko-chevron-down: "\e905";
$icon-gecko-chevron-left: "\e906";
$icon-gecko-chevron-right: "\e907";
$icon-gecko-chevron-up: "\e908";
$icon-gecko-delete: "\e909";
$icon-gecko-direction-left: "\e90a";
$icon-gecko-direction-right: "\e90b";
$icon-gecko-facebook: "\e90c";
$icon-gecko-googleplus: "\e90d";
$icon-gecko-instagram: "\e90e";
$icon-gecko-linkedin: "\e90f";
$icon-gecko-list-arrow: "\e910";
$icon-gecko-minus: "\e911";
$icon-gecko-pinterest: "\e912";
$icon-gecko-play: "\e913";
$icon-gecko-plus: "\e914";
$icon-gecko-rss: "\e915";
$icon-gecko-screenshot: "\e916";
$icon-gecko-search: "\e917";
$icon-gecko-show-lines: "\e918";
$icon-gecko-time: "\e919";
$icon-gecko-twitter: "\e91a";
$icon-gecko-user: "\e91b";
$icon-gecko-youtube: "\e92a";



@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-gecko-"], [class*=" icon-gecko-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gecko-offline {
  &:before {
    content: $icon-gecko-offline; 
  }
}
.icon-gecko-online {
  &:before {
    content: $icon-gecko-online; 
  }
}
.icon-gecko-sync {
  &:before {
    content: $icon-gecko-sync; 
  }
}
.icon-gecko-unavailable {
  &:before {
    content: $icon-gecko-unavailable; 
  }
}
.icon-gecko-dashboard {
  &:before {
    content: $icon-gecko-dashboard; 
  }
}
.icon-gecko-download {
  &:before {
    content: $icon-gecko-download; 
  }
}
.icon-gecko-like {
  &:before {
    content: $icon-gecko-like; 
  }
}
.icon-gecko-reply {
  &:before {
    content: $icon-gecko-reply; 
  }
}
.icon-gecko-external-link {
  &:before {
    content: $icon-gecko-external-link; 
  }
}
.icon-gecko-knowledge {
  &:before {
    content: $icon-gecko-knowledge; 
  }
}
.icon-gecko-overview {
  &:before {
    content: $icon-gecko-overview; 
  }
}
.icon-gecko-scroll-down {
  &:before {
    content: $icon-gecko-scroll-down; 
  }
}
.icon-gecko-poll {
  &:before {
    content: $icon-gecko-poll; 
  }
}
.icon-gecko-eye {
  &:before {
    content: $icon-gecko-eye; 
  }
}
.icon-gecko-activity {
  &:before {
    content: $icon-gecko-activity; 
  }
}
.icon-gecko-connect {
  &:before {
    content: $icon-gecko-connect; 
  }
}
.icon-gecko-audio {
  &:before {
    content: $icon-gecko-audio; 
  }
}
.icon-gecko-discussion {
  &:before {
    content: $icon-gecko-discussion; 
  }
}
.icon-gecko-globe {
  &:before {
    content: $icon-gecko-globe; 
  }
}
.icon-gecko-home {
  &:before {
    content: $icon-gecko-home; 
  }
}
.icon-gecko-light {
  &:before {
    content: $icon-gecko-light; 
  }
}
.icon-gecko-question {
  &:before {
    content: $icon-gecko-question; 
  }
}
.icon-gecko-text {
  &:before {
    content: $icon-gecko-text; 
  }
}
.icon-gecko-timer {
  &:before {
    content: $icon-gecko-timer; 
  }
}
.icon-gecko-video {
  &:before {
    content: $icon-gecko-video; 
  }
}
.icon-gecko-grid {
  &:before {
    content: $icon-gecko-grid; 
  }
}
.icon-gecko-map {
  &:before {
    content: $icon-gecko-map; 
  }
}
.icon-gecko-scroll-up {
  &:before {
    content: $icon-gecko-scroll-up; 
  }
}
.icon-gecko-sidebar {
  &:before {
    content: $icon-gecko-sidebar; 
  }
}
.icon-gecko-calendar {
  &:before {
    content: $icon-gecko-calendar; 
  }
}
.icon-gecko-cart {
  &:before {
    content: $icon-gecko-cart; 
  }
}
.icon-gecko-check {
  &:before {
    content: $icon-gecko-check; 
  }
}
.icon-gecko-chevron-down {
  &:before {
    content: $icon-gecko-chevron-down; 
  }
}
.icon-gecko-chevron-left {
  &:before {
    content: $icon-gecko-chevron-left; 
  }
}
.icon-gecko-chevron-right {
  &:before {
    content: $icon-gecko-chevron-right; 
  }
}
.icon-gecko-chevron-up {
  &:before {
    content: $icon-gecko-chevron-up; 
  }
}
.icon-gecko-delete {
  &:before {
    content: $icon-gecko-delete; 
  }
}
.icon-gecko-direction-left {
  &:before {
    content: $icon-gecko-direction-left; 
  }
}
.icon-gecko-direction-right {
  &:before {
    content: $icon-gecko-direction-right; 
  }
}
.icon-gecko-facebook {
  &:before {
    content: $icon-gecko-facebook; 
  }
}
.icon-gecko-googleplus {
  &:before {
    content: $icon-gecko-googleplus; 
  }
}
.icon-gecko-instagram {
  &:before {
    content: $icon-gecko-instagram; 
  }
}
.icon-gecko-linkedin {
  &:before {
    content: $icon-gecko-linkedin; 
  }
}
.icon-gecko-list-arrow {
  &:before {
    content: $icon-gecko-list-arrow; 
  }
}
.icon-gecko-minus {
  &:before {
    content: $icon-gecko-minus; 
  }
}
.icon-gecko-pinterest {
  &:before {
    content: $icon-gecko-pinterest; 
  }
}
.icon-gecko-play {
  &:before {
    content: $icon-gecko-play; 
  }
}
.icon-gecko-plus {
  &:before {
    content: $icon-gecko-plus; 
  }
}
.icon-gecko-rss {
  &:before {
    content: $icon-gecko-rss; 
  }
}
.icon-gecko-screenshot {
  &:before {
    content: $icon-gecko-screenshot; 
  }
}
.icon-gecko-search {
  &:before {
    content: $icon-gecko-search; 
  }
}
.icon-gecko-show-lines {
  &:before {
    content: $icon-gecko-show-lines; 
  }
}
.icon-gecko-time {
  &:before {
    content: $icon-gecko-time; 
  }
}
.icon-gecko-twitter {
  &:before {
    content: $icon-gecko-twitter; 
  }
}
.icon-gecko-user {
  &:before {
    content: $icon-gecko-user; 
  }
}
.icon-gecko-youtube {
  &:before {
    content: $icon-gecko-youtube; 
  }
}

