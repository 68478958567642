/* ---------------------------------------------------
   sidebar-activity
----------------------------------------------------- */

#activity-wrapper{
    
    transition: all 0.5s;
    //position: absolute;
    //top:0;
    //right: 0;

}
#sidebar-activity {
background: #fff;
color: $gray-800;
transition: all 0.3s;
padding: 0;
display: block;
width: 100%;
hr {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border-top: 10px solid $gray-200;
}

.nav {
    justify-content: space-between;
    align-content: stretch;
}

.nav-item{
    width: 50%;
}
    
.nav-tabs .nav-link {
    border-radius: 0;
    border: 0;
}

.nav-link {
    background-color: $gray-100;
    padding: 1rem;
}

.nav-link.active {
    background-color: #fff;
}


.list-group-flush .list-group-item {
    padding: 0.75rem 0;
}

.list-group-flush .list-group-item:first-child {
    border-top: 0;
}
}

