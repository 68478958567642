

$sidebar-minimised-width: 60px;
$scrollbar-width: 11px;
/* sidebar widths */
$sidebar-width: 100vw; //full width below 768/tablet
$sidebar-width-md: 40%; 
$sidebar-width-lg: 30%;
$sidebar-width-xl: 30%;
$sidebar-width-xxl: 25%;



    /* main content base calculated from sidebar size - for sidebar being on */
    $section-content-width-sidebar-min: calc( 100% - #{$sidebar-minimised-width}  ); 
    $section-content-width-md: calc( 100% - #{$sidebar-width-md}  ); 
    $section-content-width-lg: calc( 100% - #{$sidebar-width-lg}  );
    $section-content-width-xl: calc( 100% - #{$sidebar-width-xl}  );
    $section-content-width-xxl: calc( 100% - #{$sidebar-width-xxl}  );


    /*section content max width*/
    $section-content-max-width: 1040px;


    /* content and activity width when sidebar is minimised and activity is shown */
        $activity-width-md-sidebar-min :$section-content-width-sidebar-min ;
        $activity-width-md: $section-content-width-md; //replaces section
        $activity-width-lg: 40%; 
        $activity-width-xl: 30%;
        $activity-width-xxl: 25%;

    $section-content-width-xl-sa: calc( 100% - #{$sidebar-width-xl}  - #{$activity-width-xl});
    $section-content-width-xxl-sa: calc( 100% - #{$sidebar-width-xxl}  - #{$activity-width-xxl});
  



/* main content padding */
    $spacer-v-xs: $spacer * 0.75;
    $spacer-h-xs: $spacer * 0.75;

    $spacer-v-md: $spacer * 1.25;
    $spacer-h-md: $spacer * 1.5;

    $spacer-v-lg: $spacer * 2;
    $spacer-h-lg: $spacer * 2.5;

    $spacer-v-xl: $spacer * 2.5;
    $spacer-h-xl: $spacer * 3.0;

    /* header heights */
    $header-height: 85px;
    $header-height-xs-up: 90px;
    $course-header-bar-height: 38px;
    
.full-height-minus-header {
    @media screen {
        height: calc(100vh - #{$header-height});

        @include media-breakpoint-up(xs) {
            height: calc(100vh - #{$header-height-xs-up});
        }
    }
}

.full-height-minus-header-and-course-header-bar {
    @media screen {
        height: calc(100vh - #{$header-height} - #{$course-header-bar-height});

        @include media-breakpoint-up(xs) {
            height: calc(100vh - #{$header-height-xs-up} - #{$course-header-bar-height});
        }
    }
}

#page-wrapper{
    background-image: url(/assets/img/journey-line.svg);
    background-repeat: no-repeat;
    background-position-y: 15rem;
   
    @media screen {
        overflow: hidden;
        @include media-breakpoint-up(md) { 
            height: 100vh;
            width:100vw;    
            display: flex;
        }
        @include media-breakpoint-up(xl) { 
            background-size: cover;
        }
        flex-direction:column;
        > div{
            flex: 1; 
        }

        .main-content {
            position: fixed;
            top: 85px;
            left: 0;
            right: 0;

            @include media-breakpoint-up(sm) {
                top: 90px;
            }

        }
        
        #content-wrapper{
            

            overflow: hidden;             
            position: relative;

            @include media-breakpoint-up(md) {  display: flex; }
        
            
            //show/hide sidebar for mobile
            @include media-breakpoint-down(sm) {
                &.sidebar-mobile-show{
                
                    #sidebar-wrapper{display: inherit;}
                    #section-content-wrapper{ display: none;} 
                    #activity-wrapper{ display: none;} 
                }
                &:not(.sidebar-mobile-show){
                
                    #sidebar-wrapper{display: none;}

                }
            }

            &.sidebar-minimised{
                #sidebar-wrapper{
                    min-width:$sidebar-minimised-width; 
                    max-width:$sidebar-minimised-width; 
                    @include media-breakpoint-up(md) {  padding-right:  $scrollbar-width;}
                    max-width: 70px !important;
                }
                
            
            }    
            &:not(.sidebar-minimised){
                #sidebar-wrapper{ 
                
                    @include media-breakpoint-up(md) { width: $sidebar-width-md; max-width: $sidebar-width-md;}
                    @include media-breakpoint-up(lg) { width: $sidebar-width-lg; max-width: $sidebar-width-lg;}
                    @include media-breakpoint-up(xl) { width: $sidebar-width-xl;max-width: $sidebar-width-xl;}
                    @include media-breakpoint-up(xxl) { width: $sidebar-width-xxl;min-width: $sidebar-width-xxl; max-width: $sidebar-width-xxl;}
                }
                #section-content-wrapper{
                    
                    @include media-breakpoint-up(md) { width: $section-content-width-md; min-width: $section-content-width-md;}
                    @include media-breakpoint-up(lg) { width: $section-content-width-lg; min-width: $section-content-width-lg;}
                    @include media-breakpoint-up(xl) { width: $section-content-width-xl; min-width: $section-content-width-xl;}
                    @include media-breakpoint-up(xxl) { width: $section-content-width-xxl; min-width: $section-content-width-xxl;}
                }
                #activity-wrapper{ 
                    @include media-breakpoint-up(md) {width: $activity-width-md;}
                }
                &.activity-show{
                
                    #section-content-wrapper{
                    
                        @include media-breakpoint-up(xl) { width: $section-content-width-xl-sa; min-width: $section-content-width-xl-sa;}
                        @include media-breakpoint-up(xxl) { width: $section-content-width-xxl-sa; min-width: $section-content-width-xxl-sa;}
                    }
                }
            }

            &.activity-show{
                &:not(.sidebar-mobile-show){
                display: inherit;
                }
                @include media-breakpoint-down(md) {
                    #section-content-wrapper{display: none; } 
                }
            
                @include media-breakpoint-up(md) {
                    #sidebar-wrapper{display: inherit;}
                
                }
                @include media-breakpoint-up(xl) {
                    #section-content-wrapper{display: inherit; } 
                
                }
            }

            &:not(.activity-show){
                #activity-wrapper{ display: none;}  
            }

            
            #sidebar-wrapper{ 
                width:100%;
                overflow-y:auto;
                
            }
            #section-content-wrapper{
                //border:1px solid red;
                transition: all 0.5s;
            
                overflow-y:scroll;
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;
                /*
                @include media-breakpoint-down(sm) {
                    padding:  $spacer-v-xs $spacer-h-xs $spacer-v-xs $spacer-h-xs;
                    //margin-right: $scrollbar-width;
                }
                */
                
                
                
                @include media-breakpoint-up(md) { 
                    //padding:  $spacer-v-md 0 $spacer-v-md 0;
                    
                    width:$section-content-width-sidebar-min;
                   /*
                    @include media-breakpoint-up(lg) {
                        margin:  0;
                    }
                    #section-content{
                        padding-left:$spacer-h-md;
                        padding-right:  calc( #{$scrollbar-width} + #{$spacer-h-md}  ) ;
                        max-width: $section-content-max-width;
                        margin: 0 auto;
                        @include media-breakpoint-up(lg) {
                            padding: $spacer-v-lg  $spacer-h-lg;
                            padding-right:  calc( #{$scrollbar-width} + #{$spacer-h-lg}  ) ;
                        }
                        @include media-breakpoint-up(xl) {
                            padding:  $spacer-v-xl  $spacer-h-xl;
                            padding-right:  calc( #{$scrollbar-width} + #{$spacer-h-xl}  ) ;
                        }
                    }
                    */
                }
            

            
            
            

            }

            #activity-wrapper{ 
                
                width:100%;
                overflow-y:auto;
                -webkit-overflow-scrolling: touch;

                @include media-breakpoint-up(md) { width: $activity-width-md-sidebar-min; min-width: $activity-width-md-sidebar-min; }
                @include media-breakpoint-up(lg) { width: $activity-width-lg;  min-width: $activity-width-lg;}
                @include media-breakpoint-up(xl) { width: $activity-width-xl;  min-width: $activity-width-xl; max-width: $activity-width-xl;}
                @include media-breakpoint-up(xxl) { width: $activity-width-xxl;  min-width: $activity-width-xxl; max-width: $activity-width-xxl;}
            }

            
        }
    }
    
}

#section-content-wrapper{
    
    @include media-breakpoint-down(sm) {
        padding:  $spacer-v-xs $spacer-h-xs $spacer-v-xs $spacer-h-xs;
    }
    @include media-breakpoint-up(md) { 
        padding:  $spacer-v-md 0 $spacer-v-md 0;

        @include media-breakpoint-up(lg) {
            margin:  0;
        }

        #section-content{
            padding-left:$spacer-h-md;
            padding-right:  calc( #{$scrollbar-width} + #{$spacer-h-md}  ) ;
            margin: 0 auto;
            @include media-breakpoint-up(lg) {
                padding: $spacer-v-lg  $spacer-h-lg;
                padding-right:  calc( #{$scrollbar-width} + #{$spacer-h-lg}  ) ;
            }
            @include media-breakpoint-up(xl) {
                padding:  $spacer-v-xl  $spacer-h-xl;
                padding-right:  calc( #{$scrollbar-width} + #{$spacer-h-xl}  ) ;
            }
        }
    }    
}    

@media screen {
    #section-content-wrapper {
        overflow-y: scroll !important;
    }
}

//table overrides - first tr is the header when within the content
#section-content-wrapper {
    .table-responsive {
        table {
            td {
                p {
                    margin: 0;
                }
            }

            tr:first-child {
                background: #f7f7f7;
                font-weight: bold;
                font-size: 1.1rem;

                td {
                    border-top: none;
                }
            }
        }
    }
}
