.course-admin-wrapper {
  .table-responsive {
    overflow-y: scroll;
    margin-top: 30px;
    box-shadow: 0 0 10px 1px #ddd;
    border-radius: 6px;

    table {
      width: 100%;

      tr:first-child {
        background: #fff;
      }
    }

    .header-row {
      background-color: #666 !important;
      color: #fff !important;
      box-shadow: 0 0 15px 5px #333 !important;
    }

    .body-row {
      font-weight: normal;
      font-size: 0.85rem;

      td {
        word-break: break-all;
        white-space: normal;
        overflow-wrap: break-word;

      }
    }
  }
}