.custom-input {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default input */
.custom-input input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox and radio button */
.checkmark, .radiobtn {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $gray-300;
}

.radiobtn {
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.custom-input:hover input ~ .checkmark {
    background-color: $gray-500;
}
.custom-input:hover input ~ .radiobtn {
    background-color: $gray-500;
}

/* When the input is checked, add a primary background */
.custom-input input:checked ~ .checkmark {
    background-color: $primary;
}
.custom-input input:checked ~ .radiobtn {
    background-color: $primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after, .radiobtn:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-input input:checked ~ .checkmark:after {
    display: block;
}

.custom-input input:checked ~ .radiobtn:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-input .checkmark:after {
    left: 9px;
    top: 5px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.custom-input .radiobtn:after {
    top: 9px;
    left: 9px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
}

.section-widget{

    .btn-vote{
        &.collapsed{
            background-color: $white;
            &:hover {
                background: $gray-300;
                color: $primary;
            }
        }
        &:not(.collapsed){
            background-color: $primary;
            color: $white;
        }
    }
}


.text-area-scroll{
    max-height: 400px;
    overflow: auto;
}

@media screen and (max-width: 576px) {
    .text-area-scroll{
        max-height: 200px;
    }
    
}